// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YXtZ6jTH{display:flex;flex-direction:column;min-height:100vh;padding-top:var(--header-height)}.fm75nYUo,.ju\\+pM8L5{display:flex;flex-direction:column;flex-grow:1}.ju\\+pM8L5{margin:0 auto;max-width:var(--content-width);width:100%}@media(max-width:1229.98px){.ZNnRMYH\\+{padding:0 var(--spacing-md)}}@media(max-width:667.98px){.ZNnRMYH\\+{padding:0 var(--spacing-sm)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "YXtZ6jTH",
	"content": "fm75nYUo",
	"inner": "ju+pM8L5",
	"innerPadding": "ZNnRMYH+"
};
module.exports = ___CSS_LOADER_EXPORT___;
