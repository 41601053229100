// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LzBDUOLw{justify-content:center;min-height:100vh;overflow-x:hidden;overflow-y:auto;padding:var(--spacing-md)}.LzBDUOLw,.wkRGxf5q{align-items:center;display:flex}.wkRGxf5q{position:relative}@media(max-width:947.98px){.wkRGxf5q{flex-direction:column}}.lCtmCL3r{color:var(--color-bg-invert);font-size:22.63rem;font-weight:700;left:0;line-height:1;opacity:.1;pointer-events:none;position:absolute;top:0}@media(max-width:947.98px){.lCtmCL3r{font-size:11.38rem;left:50%;top:30%;transform:translate(-50%,-50%)}}.HwrroYZL{position:relative}@media(max-width:947.98px){.HwrroYZL{transform:translateX(.5rem)}}._1YxicUFj{color:var(--color-primary);height:13rem;position:absolute;right:1rem;top:50%;transform:translateY(-50%);width:17.63rem}@media(max-width:947.98px){._1YxicUFj{height:6.56rem;right:.5rem;width:8.88rem}}.Pr3rs7Xr{color:var(--color-bg-invert);font-size:8.13rem;font-weight:700;line-height:1.46;position:relative;-webkit-user-select:none;-moz-user-select:none;user-select:none}@media(max-width:947.98px){.Pr3rs7Xr{font-size:4.06rem}}.AOXx-jAF{padding-left:var(--spacing-xxl);position:relative}@media(max-width:947.98px){.AOXx-jAF{align-items:center;display:flex;flex-direction:column;padding-left:0;padding-top:var(--spacing-lg);text-align:center}}.UeCuGsPJ{color:var(--color-gray-4);font-size:1.5rem;font-weight:700;line-height:1.46;margin-bottom:1.46em;max-width:19rem}@media(max-width:947.98px){.UeCuGsPJ{font-size:1.25rem;margin-bottom:.8em;max-width:100%}}._0GQjVoJl{color:var(--color-gray-4);font-size:.88rem;line-height:1.43;margin-bottom:2.14em;max-width:20rem}@media(max-width:947.98px){._0GQjVoJl{font-size:.81rem;margin-bottom:1.5em;max-width:80%}}.Mg-Ou0qi{color:var(--color-primary);font-size:.88rem;font-weight:600;line-height:1.57}@media(hover:hover){.Mg-Ou0qi:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.Mg-Ou0qi:active{-webkit-text-decoration:underline;text-decoration:underline}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "LzBDUOLw",
	"inner": "wkRGxf5q",
	"bg": "lCtmCL3r",
	"status": "HwrroYZL",
	"statusFrame": "_1YxicUFj",
	"statusCode": "Pr3rs7Xr",
	"info": "AOXx-jAF",
	"title": "UeCuGsPJ",
	"description": "_0GQjVoJl",
	"link": "Mg-Ou0qi"
};
module.exports = ___CSS_LOADER_EXPORT___;
