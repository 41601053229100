import { wrap } from "../_"

const pfx = "/badge"

export default (a: NuxtAxiosInstance) => ({
  generateColleagueBadge: (colleagueId = "", cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/generate/${colleagueId}`, {},
      { responseType: "blob", ...cfg })),

  getBadge: () => wrap(
    a.$post("/graphql", {
      query: /* gql */ `
        query {
          downloadTicket
        }
      `,
    }, {
      responseType: "blob",
    }),
  ),
})
