// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HFC60fIw{background:var(--color-bg);filter:drop-shadow(0 .25rem 1.25rem rgba(var(--color-shadow-rgb),.25));height:var(--header-height);left:0;padding:0 var(--spacing-md);position:fixed;right:0;top:0;z-index:10}@media(max-width:1023.98px){.HFC60fIw{filter:none;padding:0}}.MU8b3TNx{align-items:center;display:flex;height:100%;justify-content:space-between;margin:0 auto;max-width:var(--content-width)}@media(max-width:1023.98px){.MU8b3TNx{box-shadow:0 .25rem 1.25rem rgba(var(--color-shadow-rgb),.25);padding:0 var(--spacing-md)}}@media(max-width:667.98px){.MU8b3TNx{padding:0 var(--spacing-sm)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "HFC60fIw",
	"content": "MU8b3TNx"
};
module.exports = ___CSS_LOADER_EXPORT___;
