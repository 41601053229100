// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhkmPlox{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none;border:none;color:var(--color-fg);cursor:pointer;flex-shrink:0;font-size:.88rem;font-weight:600;letter-spacing:.75px;line-height:1.2;padding:.82em 1.14em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"select": "WhkmPlox"
};
module.exports = ___CSS_LOADER_EXPORT___;
