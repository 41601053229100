// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hRreBsDG{flex-shrink:0;height:var(--size);width:var(--size)}.R-\\+ySFrN *{fill:currentColor}.ETGOWNl6 *{stroke:currentColor}.e6jVLkKH{background:currentColor;border-radius:9999px;opacity:.5;overflow:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"icon": "hRreBsDG",
	"iconPaintFill": "R-+ySFrN",
	"iconPaintStroke": "ETGOWNl6",
	"iconPlaceholder": "e6jVLkKH"
};
module.exports = ___CSS_LOADER_EXPORT___;
