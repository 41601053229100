// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lVTHruco{align-items:center;color:var(--color-bg-invert);display:flex;font-size:.75rem;justify-content:center;line-height:1.6}@media(max-width:667.98px){.lVTHruco{flex-direction:column;font-size:.69rem}}.sPJUsR12{align-items:center;color:inherit;display:flex;flex-wrap:nowrap;font-weight:700;margin-right:var(--spacing-md);white-space:nowrap}@media(max-width:667.98px){.sPJUsR12{margin-right:0}}.XRI0QZpl{flex-shrink:0;height:2rem;margin-right:var(--spacing-sm)}.XRI0QZpl>*{fill:currentColor}.sPJUsR12,.QUZ5GTuo{min-height:3rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "lVTHruco",
	"copy": "sPJUsR12",
	"copyLogo": "XRI0QZpl",
	"terms": "QUZ5GTuo"
};
module.exports = ___CSS_LOADER_EXPORT___;
